
// ABSTRACTS
@import 'abstracts/variables';

//pages
@import "./pages/pages";


//components
@import "./components/modalCalculator";
@import "./components/searchInput";
@import "./components/cropModal";
@import "./components/newBrand";
@import "./components/carousel";
@import "./components/button";
@import "./components/brand";
@import "./components/img";
@import "./components/sideMenu";
@import "./components/footer";
@import "./components/profileCard";
@import "./components/colors";
@import "./components/customCheckbox";
@import "./components/customInput";
@import "./components/customSelect";
@import "./components/primaryButton";
@import "./components/secondaryButton";
@import "./components/creditCard";
@import "./components/thumb";
@import "./components/customTextArea";
@import "./components/CustomMultSelect";
@import "./components/article";

.accessibility {
  font-size: 0;
}

//global
@import "global";
