.patient__physician__description{
  padding: 3rem;
  @media only screen and (max-width: 575px) {
    padding: 15px;
  }
    &__title{
      font-size: 16px;
      color: $colorThird;
      padding-bottom: 0.625rem;
      text-transform: uppercase;
      font-weight: 600;
        @media only screen and (max-width: 575px) {
          &.about{
            margin-top: 1.875rem;
          }
        }
    }
  &__text{
    font-size: 16px;
    line-height: 1.25rem;
    color:$colorThird;
  }

  &__favorite__icon{
    width: 1.563rem;
    height: 1.5rem;
    margin-right: 0.625rem;
  }

  &__telemed__button{
    background-color: $colorTelemed;
    color: #fff;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 700;
    border: 1px solid $colorTelemed;
    height: 2.5rem;
    min-width: 12.813rem;
    &:hover{
        opacity: 0.7;
      }

  }
  &__favorite__button{
    min-width:  12.813rem;
    border: 1px solid #E5E5E5;
    padding: 0.375rem 0.75rem;
    &:hover{
      opacity: 0.7;
    }

  }
  &__badge__buttons{
    background-color: $colorPrimary;
    color: #fff;
    border-radius: 16px;
    padding: 3px 1.563rem;
    margin-top: 10px;
    margin-right: 0.625rem;
    text-transform: capitalize;
    font-size: 16px;
    display: inline-block;

  }
  .profile__card{
    @media only screen and (max-width: 575px) {
      flex-direction: column;
      text-align: center;
    }

  }
  .profile__card__description{
    @media only screen and (max-width: 575px) {
        margin-top: 0.938rem;
    }
  }
  &__skeleton{
      &__card{
        @media only screen and (max-width: 575px) {
          flex-direction: column;
          text-align: center;
        }
        &__full{
          @media only screen and (max-width: 575px) {
            width: 70%!important;
          }
        }
        .rounded-circle{
          @media only screen and (max-width: 575px) {
           margin-bottom:  0.938rem;
          }
        }


      }

  }
}
