.profile__card{
  display: flex;
  padding-left: 0;
  align-items: center;
  &__img-box{
    img{
      width: 5rem;
      height: 5rem;
    }

  }
  &__description{
    color: $colorThird;
    &__name{
      font-size: 18px;
      font-weight: 700;
    }
    &__crm{
      font-size: 16px;
      padding: 0.313rem 0;
    }
    &__telemed{
      font-size: 16px;
    }
  }

}
