@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
body{
  font-family: 'Roboto', sans-serif;
}


.default-green{
  color: $defaultGreen;
}

.error{
  color: $colorRed;
}

input.error, select.error{
  border-color:$colorRed ;
}

