.button__primary{
  color: #fff;
  background-color: $colorPrimary;
  border:1px solid $colorPrimary;
  text-transform: uppercase;
  font-size:16px;
  font-weight: 700;
  text-align: center;
  padding: 0.625rem;
  &:hover{
    background-color: rgba($colorPrimary,0.8);
  }
}
