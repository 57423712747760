.admin__physition__list{
  .physician-card{
    padding-left: 0;
  .no-gutters{
    margin-left: -15px;
    .physician-card-avatar{
      width: 100%;
      height: 100%;
      padding-right: 20px;
      padding-left: 0!important;
    }
  }
  }
}
