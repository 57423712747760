.admin-order-card-img {
  width: 200px;
  height: 200px;
}


.border-radius-8 {
  border-radius: 6px !important;
}

.border-radius-left-8 {
  border-radius: 8px 0 0 8px !important;
}


.discount-input {
  width: 10rem !important;
  text-align: right;
  border-color: $colorGreyLight;
  padding-right: 4px !important;
}
