//==================================
//Color base
//==================================
$colorTransparent: transparent;
$colorWhite: #FFFFFF;
$colorBlack: #2D2D2D;
$colorBlackDark: #000000;
$colorGreyLighter: #F2F2F2;
$colorGreyLight: #C4C4C4;
$colorGreyDark: #5C5C5C;
$colorGrey: #6F6F6F;
$colorPrimary: #72C7B6;
$colorSecondary: #5DAA60;
$colorTertiary: #71AA74;
$colorBlue: #1C2960;
$colorThird:#414141;
$defaultGreen:#0fd447;
$colorTelemed:#DB8EF8;
$colorRed:#FF2D55;



  //==================================
//Fonts
//==================================
$font-primary: Roboto, -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
'Helvetica Neue', Arial, sans-serif !default; // Primary font: Roboto
$font-secondary: Roboto, -apple-system, system-ui, BlinkMacSystemFont,
'Segoe UI', 'Helvetica Neue', Arial, sans-serif !default; // Secondary font: Montserrat
